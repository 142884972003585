// 于管理用户的相关数据
import { defineStore } from 'pinia'
import { useAppStore } from '@/stores/app'
import { config } from '@/config'
import router from '@/router'
import { http_post } from '@/axios'
import { asyncRoutes, platform } from '@/router/asyncRoutes'
import * as Sentry from '@sentry/vue'
import { computed } from 'vue'

// 定义一个名为 "user" 的 store(数据管理对象)
export const useUserStore = defineStore('user', {
  /*****************************************
   * 定义当前对象会保存哪些基础数据
   *****************************************/
  state: () => ({
    /**用户id */
    userId: 0,
    /**用户账号 */
    account: 'coco',
    /**角色名，比如: 普通用户、测试用户、管理员账号 */
    role_name: '',
    /**角色ID */
    role_id: -1,
    /**
     * jwt字符串
     * "_"代表这是个不希望外界调用的属性
     */
    _jwt: '',
    /** 路由基础配置 */
    routes: [],
    /** 页面权限 */
    permission: {},
    /** 首页 */
    homePage: '',
    /** 是否为财务团队账号 */
    is_finance_team: false,
    /** 顶部信息设置 */
    user_settings: {
      r2_base_url: '',
      top_info_configs: [],
    },
    /** 上次播放声音的时间 */
    lastPlaySoundTs: {},
  }),

  /*****************************************
   * 取值：对 state 里的数据加工后返回
   *****************************************/
  getters: {
    /**
     * jwt的数据
     * @returns {Object}
     */
    jwtData() {
      if (!this._jwt) {
        return {}
      }

      // 解析JWT中间payload数据
      const tokenParts = this._jwt.split('.')
      if (tokenParts.length !== 3) {
        return {}
      }
      // 尝试将数据转成json对象
      let payload = null
      try {
        payload = JSON.parse(atob(tokenParts[1]))
      } catch (error) {
        return {}
      }

      // jwt已经失效
      if (!payload.exp || Math.floor(Date.now() / 1000) > payload.exp) {
        return {}
      }

      // return payload['data']
      return payload
    },

    /**
     * 是否为开发账号
     */
    isDev() {
      const jwt_data = this.jwtData
      if (!jwt_data) {
        return false
      }
      return jwt_data.roleId === 0
    },
  },

  /*****************************************
   * 自定义函数
   *****************************************/
  actions: {
    /**
     * 获取顶部信息是否显示
     * @param {string} name - 信息名称
     * @returns {boolean} - 是否显示
     */
    getTopInfoDisplay(name) {
      const _name = name
      const item = this.user_settings?.top_info_configs?.find((item) => item.name === _name)
      if (item) {
        return item.display === 1
      }
      return true
    },
    getTopInfoPlayInterval(name) {
      const item = this.user_settings?.top_info_configs?.find((item) => item.name === name)
      if (item) {
        if (item.sound_repeat_interval < 20) {
          return 20 * 1000
        }
        return item.sound_repeat_interval * 1000
      }
      return 3000 * 1000
    },
    getTopInfoPlaySound(name) {
      const item = this.user_settings?.top_info_configs?.find((item) => item.name === name)
      if (item) {
        return item.display === 1 && item.sound_play === 1
      }
      return true
    },
    getTopInfoSoundUrl(name) {
      const item = this.user_settings?.top_info_configs?.find((item) => item.name === name)
      if (item) {
        return item.sound_id && item.sound_id > 0 ? this.user_settings.r2_base_url + '/' + item.sound_url : ''
      }
      return ''
    },
    /**
     * 获取上次播放声音的时间
     * @param {string} name - 信息名称
     * @returns {number} - 上次播放声音的时间
     */
    getLastPlaySoundTs(name) {
      if (!this.lastPlaySoundTs[name]) {
        this.setLastPlaySoundTs(name, 0)
      }
      return this.lastPlaySoundTs[name]
    },
    /**
     * 设置上次播放声音的时间
     * @param {string} name - 信息名称
     * @param {number} ts - 时间
     */
    setLastPlaySoundTs(name, ts) {
      this.lastPlaySoundTs[name] = ts
    },
    /**
     * 获取顶部信息播放次数
     * @param {string} name - 信息名称
     * @returns {number} - 播放次数
     */
    getTopInfoPlayTimes(name) {
      const item = this.user_settings?.top_info_configs?.find((item) => item.name === name)
      if (item) {
        return item.sound_repeat_times <= 0 ? Infinity : item.sound_repeat_times
      }

      return Infinity
    },
    /**
     * 获取配置中，是否有能播放声音的
     */
    getWillPlaySound() {
      return this.user_settings?.top_info_configs?.some((item) => item.display === 1 && item.sound_play === 1)
    },
    /**
     * 登录成功，设置登录后信息
     */
    loginSuccess(account, response) {
      console.error('loginSuccess', account, response)

      this.user_settings = response.user_settings

      try {
        this.account = account
        const jwt_data = this.jwtData
        this.role_id = jwt_data.roleId
        this.userId = jwt_data.userId
        this.is_finance_team = jwt_data.is_finance_team
        this.role_name = response.roleName
        this.permission = response.permission
        const isServerAsyncRoutes = import.meta.env.VITE_IS_SERVER_ROUTE === 'true'

        Sentry.setUser({
          id: this.userId,
          username: this.account,
        })

        let tempHomePage = null
        const organizeDataCb = (data) => {
          if (Array.isArray(data)) {
            // 子菜单构造
            const result = []
            data.forEach((item) => {
              const temp = organizeDataCb(item)
              if (temp !== undefined && temp !== null) {
                result.push(temp)
              }
            })
            return result
          } else if (data !== null && typeof data === 'object') {
            if (!isServerAsyncRoutes) {
              data.meta = data.meta !== undefined ? data.meta : {}
              data.meta.title = data.meta.title !== undefined ? data.meta.title : {}
              data.meta.public = data.meta.public !== undefined ? data.meta.public : {}
            } else {
              data.title = JSON.parse(data.title)
            }

            let menu = {
              path: data.path !== undefined && data.path !== null && data.path !== '' ? data.path : data.name,
              name: data.name,
              component: isServerAsyncRoutes ? data.component.replace('{platform}', '${platform}') : data.component,
              redirect: data.redirect,
              meta: {
                icon: isServerAsyncRoutes ? data.icon : data.meta.icon,
                hidden: isServerAsyncRoutes ? false : data.meta.hidden,
                needLogin: isServerAsyncRoutes ? true : data.meta.needLogin === undefined || data.meta.needLogin,
                title: {
                  en: isServerAsyncRoutes ? (data.title_en ? data.title_en : data.title.en) : data.meta.title.en,
                  zh: isServerAsyncRoutes ? (data.title_zh ? data.title_zh : data.title.zh) : data.meta.title.zh,
                  ko: isServerAsyncRoutes ? (data.title_ko ? data.title_ko : data.title.ko) : data.meta.title.ko,
                  vi: isServerAsyncRoutes ? (data.title_vi ? data.title_vi : data.title.vi) : data.meta.title.vi,
                  th: isServerAsyncRoutes ? (data.title_th ? data.title_th : data.title.th) : data.meta.title.th,
                  my: isServerAsyncRoutes ? (data.title_my ? data.title_my : data.title.my) : data.meta.title.my,
                },
                cache: isServerAsyncRoutes ? false : data.meta.cache,
                permission: isServerAsyncRoutes ? data.permission : data.meta.permission,
                public: {
                  desktop: isServerAsyncRoutes ? 1 === data.enable_desktop : data.meta.public.desktop,
                  mobile: isServerAsyncRoutes ? 1 === data.enable_mobile : data.meta.public.mobile,
                },
              },
            }
            console.log(menu.name, menu, data)

            if (!menu.meta.public[platform]) {
              return null
            }

            // 有子菜单
            const tempChildren = organizeDataCb(data.children)
            if (tempChildren) {
              menu.children = tempChildren
              // 删除目录原始的component
              delete menu.component

              // 当访问目录的时候, 默认的跳转地址
              if (tempChildren.length > 0) {
                menu.redirect = { name: tempChildren[0].name }
              }
            } else {
              if (!tempHomePage) {
                tempHomePage = data.name
              }
            }

            return menu
          }
          return null
        }

        if (isServerAsyncRoutes) {
          // console.error(this.routes)
          this.routes = organizeDataCb(response.routes)
          this.homePage = tempHomePage
        } else {
          this.homePage = import.meta.env.VITE_ROUTE_NAME_HOME
        }
        if (this.homePage === '' || this.homePage === null) {
          this.homePage = '/'
        }

        // console.error(this.routes)
        // 重新加载路由配置
        router.reloadRoutes(true)

        tryRunKeepAliveTimer()

        console.warn('homePage', this.homePage)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    /**
     * 注销登录
     */
    logout(sendRequest = false) {
      Sentry.setUser(null)

      // 未登录
      if (!this._jwt || this.userId <= 0) {
        if (router.currentRoute.value.name !== import.meta.env.VITE_ROUTE_NAME_LOGIN) {
          router.push({ name: import.meta.env.VITE_ROUTE_NAME_LOGIN })
          return true
        }
        return false
      }

      const clearDataCb = () => {
        this.userId = 0
        this.account = ''
        this.role_name = ''
        this.role_id = -1
        this._jwt = ''
        this.routes = []
        this.permission = {}
        this.homePage = ''
        this.is_finance_team = false

        useAppStore().openedTabs.splice(0)
        useAppStore().openedMenuGroup = ''

        // 停止保持在线的定时器
        if (keepAliveTimerId) {
          clearInterval(keepAliveTimerId)
          keepAliveTimerId = undefined
        }

        router.push({ name: import.meta.env.VITE_ROUTE_NAME_LOGIN })
      }

      if (sendRequest) {
        http_post('/api/admin/user/logout', {}, false)
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            clearDataCb()
          })
      } else {
        clearDataCb()
      }

      return true
    },

    stopKeepAlive() {
      if (keepAliveTimerId) {
        clearInterval(keepAliveTimerId)
        keepAliveTimerId = undefined
      }
    },

    /**
     * 更新JWT
     */
    setJwt(jwt) {
      this._jwt = jwt
    },
    /**
     * 登录状态
     * @returns {number} -1未登录；0已登录；1登录失效；
     */
    getLoginStatus() {
      // 未登录
      if (!this._jwt || this.userId <= 0) {
        return -1
      }

      // 解析JWT中间payload数据
      const tokenParts = this._jwt.split('.')
      if (tokenParts.length !== 3) {
        console.error('JWT token is not in the correct format.')
        return 1 // 错误的jwt，视为登录失效
      }
      // 尝试将数据转成json对象
      let payload = null
      try {
        payload = JSON.parse(atob(tokenParts[1]))
      } catch (error) {
        console.error('Error parsing JWT payload.')
        return 1 // 错误的jwt，视为登录失效
      }

      // 检查是否存在exp声明, 如果为声明视为登录失效
      if (!payload.exp) {
        return 1
      }

      // jwt已经失效，登录失效
      if (Math.floor(Date.now() / 1000) > payload.exp) {
        return 1
      }

      tryRunKeepAliveTimer()
      // 已登录
      return 0
    },
  },

  /*****************************************
   * 持久化数据配置
   *****************************************/
  persist: {
    key: 'user', // 区分不同store
    enabled: true,
    strategies: [{ storage: localStorage }],
  },
})

let keepAliveTimerId = null

async function tryRunKeepAliveTimer() {
  if (keepAliveTimerId) {
    return
  }

  // 启动保持在线的定时器
  keepAliveTimerId = setInterval(async () => {
    // 保持用户在线
    await http_post('/api/admin/user/keepOnline', {}, false, 0, false)
      .then(() => {})
      .catch(() => {})
  }, 20000) // 每20秒发送一次请求
}
